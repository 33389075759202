import React from 'react';
import logo from './img/logo.png';
import './App.css';
import Widget from './Widget';
import SourceWidget from './SourceWidget';
import ReactLoading from 'react-loading';
import FadeIn from 'react-fade-in';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";


var HttpClient = function() {
    this.get = function(aUrl, aCallback) {
        var anHttpRequest = new XMLHttpRequest();
        anHttpRequest.onreadystatechange = function() {
            if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200){
                aCallback(anHttpRequest.responseText);
            }
        }

        anHttpRequest.open( "GET", aUrl, true );
        anHttpRequest.send( null );
    }
}

class App extends React.Component {
  constructor(){
    super();
    this.state = {
      articleShowing: false,
      article: "",
      title:"",
      author:"Loading...",
      source:"",
      authorID:"n/a",
      date:"",
      authorDetails: "",
      test: "",
      stockDetails: "",
      image:null,
      numCorrect: "", //make sure this is a percentage out of total
      total: "",
      expReturn: "", // make sure this is a percentage as well
      loading:false,
      slant: null,
      stars: null,
      rank: null,
      credibility: "",
      readTime: null,
      content:"",
      // accuracy: null,
      stock:null,
      // url:""

    }
  }

  getAuthor(author) {
    if (author == "â") {
      return "This author"
    } else{
      return author
    }
  }

  getAccuracy(total, acc) {
    if (parseInt(total) < 10) {
      return "";
    }
    if (acc == "NaN%" || acc == "0%") {
      return "0%"
    } else{
      return acc
    }
  }

  checkUndefined(total) {
    if (parseInt(total) < 10) {
      return ""
    } else {
      return total
    }
  }

  componentDidMount(){
    if(window.location.href.includes("url=")){
        this.runUrl(window.location.href.split("url=")[1]);
    }
  }


  handleKeyPress = (e) =>{
    if(e.key === 'Enter'){
        this.runUrl(e.target.value);
    }
  }

  runUrl = (url) =>{
    console.log(url);
    if(!this.validURL(url)){
        window.alert('That is not a valid url.');
    }
    else{
      var client=new HttpClient();
      var self = this;
      var entry = url;
      this.setState({
        loading:true,
        articleShowing: false,
        // url:url
      });
      entry = entry.split("?")[0];
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify([url])

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            fetch(proxyurl + "http://api.nobias.com/v1/spotcheck/finance/news", requestOptions)
              .then(response => console.log(response))
              .then(data => data[0])
              .then(res =>
                self.setState({
                  credibility: "" + res.accurate,
                  author: this.getAuthor(res.author?.name),
                  rank: res.author?.rank,
                  stars: res.author?.stars,
                  authorID: res.author?.authorId,
                  expReturn: this.getAccuracy(res.author?.n_buy + res.author?.n_sell + res.author?.n_neutral, Math.round(res.author?.expected_return) + "%"),
                  total: this.checkUndefined(res.author?.n_buy + res.author?.n_sell + res.author?.n_neutral),
                  // numCorrect: res.author?.n_correct,
                  numCorrect: this.getAccuracy(res.author?.n_buy + res.author?.n_sell + res.author?.n_neutral, Math.round(((res.author?.n_correct)/(res.author?.n_correct+res.author?.n_wrong))*100) + "%"),
                  authorDetails: "https://spotcheckf.nobias.com/authors?authorid=" + res.author?.authorId,
                  content: res.content.split(" ").reverse().slice(40).reverse().join(" ") + "...",
                  // content:res.content,
                  slant: res.nobiasLeaning,
                  test: res.author?.n_articles,
                  stock: res.stock.stock,
                  stockDetails: "https://spotcheckf.nobias.com/stocks?ticker=" + res.stock.stock,
                  stock_n_hold: res.stock.n_neutral,
                  stock_n_buy: res.stock.n_buy,
                  stock_n_sell: res.stock.n_sell,
                  // total: "" + (parseInt(res.stock.n_neutral) + parseInt(res.stock.n_buy) + parseInt(res.stock.n_sell)),
                  title: res.title,
                  date: res.date.substring(0,10),
                }),
                )
              .then(() => console.log("test: " + this.state.test))
              .catch(error => window.alert("Looks like we don't have that article in our database! Try a different link.",
                  self.setState({
                    credibility: "undefined",
                    author: "",
                    rank: -1,
                    stars: -1,
                    content: "",
                    // content:res.content,
                    slant: "",
                    stock: "undefined",
                    stock_n_hold: 0,
                    stock_n_buy: 0,
                    stock_n_sell: 0,
                    title: "",
                    authorID:  "undefined",
                    date:  "Try another article!",
                  }),
            ));

                self.setState({
                  loading:false,
                  articleShowing: true,
                });
                if(!window.location.href.includes(url)){
                      window.history.pushState(null, '', '?url='+url);
                }
              };

          var request = new XMLHttpRequest()

          // Open a new connection, using the GET request on the URL endpoint
          const proxyurl = "https://cors-anywhere.herokuapp.com/";
          const imageurl = "https://serpapi.com/search.json?q=NASDAQ:";
          // request.open('GET', proxyurl + imageurl + this.state.stock, true)

          var request = require('request');


          // var options = { url: 'https://app.zenserp.com/api/v2/status?apikey=d380eac0-a22b-11ea-b09d-bf1e03fdd135' };
          var options = { url: proxyurl+imageurl+ this.state.stock + "&tbm=isch&ijn=0"};

          function callback(error, response, body) {
          	if (!error && response.statusCode == 200) {
          		console.log("body: " + body);
          	} else {
              console.log(response.statusCode)
            }
          }

          request(options, callback);

          // request.onload = function() {
          //   console.log("print: " + JSON.parse(this.responseText))
          //   var data = JSON.parse(this.response)
          //   console.log("data: " + data)
          //   if (request.status >= 200 && request.status < 400) {
          //     console.log("data: " + data)
          //     this.setState({
          //       image:data[0]
          //     });
          //   } else {
          //     console.log('error')
          //   }
          // }

          // Send request
          // request.send()

  }

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  render(){
    const LoadingIndicator = props => {
          const { promiseInProgress } = usePromiseTracker();

         return (
           promiseInProgress &&
          <ReactLoading type='spin' color='222222'/>
      );
     }
    return (

      <div>

      <div className="row" style={{padding:"5%"}}>
        <img style={{cursor:"pointer"}} onClick={()=>window.location.href = window.location.href.split("?")[0]} src={logo} width="300px" />
        {/* <div style={{fontSize:"30px", fontWeight:"bold"}}>Know the bias and credibility of any article </div> */}
        <p style={{fontSize: "20px"}} > <br></br><h6 style={{fontSize:"30px", fontWeight:"bold"}}>Have you ever read investment tips and wondered how reliable they are? </h6> <br></br>Detect whether the information you are reading is <b style = {{color:"green"}}>bullish</b> or <b style = {{color:"orange"}} >bearish</b>, view the <b>credibility rating</b> for the analyst or blogger based on the accuracy of their past calls, and know the <b>context</b> (i.e. Are you reading the only bearish article on Amazon?) to ensure you aren't in a filter bubble.</p>
        <br></br><div className="input-field" >
            <input style={{fontSize:"25px", color:"#afc4e0"}} onFocus={(event) => event.target.select()} autoFocus="autofocus" placeholder="Enter an article link" onKeyDown={this.handleKeyPress}/>
        </div>
        {this.state.loading &&
          <div style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)'
          }}>
              <ReactLoading type='spin' color='222222'/>
          </div>
        }
        {this.state.articleShowing &&
            <FadeIn>
                  <div className="myrow">
                      <div className = "mycol1" style={{flexGrow: "1", padding:"10px"}}>
                          <Widget author={this.state.author} date = {this.state.date} image = {this.state.image} title={this.state.title} source={this.state.source} content = {this.state.content} url={this.state.article}/>
                      </div>
                      <div className = "mycol2" style={{flexGrow:"2", padding:"10px"}}>
                          <SourceWidget authorID = {this.state.authorID} authorDetails = {this.state.authorDetails}
                                        stockDetails = {this.state.stockDetails}
                                        author = {this.state.author} slant={this.state.slant} credibility={this.state.credibility}
                                        readTime = {this.state.readTime} stock = {this.state.stock}
                                        stock_n_buy = {this.state.stock_n_buy} stock_n_hold = {this.state.stock_n_hold}
                                        numCorrect = {this.state.numCorrect} total = {this.state.total} expReturn = {this.state.expReturn}
                                        stock_n_sell = {this.state.stock_n_sell} rank = {this.state.rank} stars = {this.state.stars}/>
                      </div>
                  </div>
            </FadeIn>
        }
      {/* <p style = {{background: "white", position: "fixed", bottom:"0%"}}><br></br><br></br><a href="https://www.nobias.com">Nobias</a> is a media technology company dedicated to promoting responsible technology and countering misinformation. In today's world, there is so much news content, it can be difficult to detect what is misleading or trustworthy. Nobias' free political news Chrome <a href="https://chrome.google.com/webstore/detail/nobias/gibdobnmepamhkigmgacgcdmidpeckda">extension</a> alerts you to the political slant, credibility, and context of news articles appearing in Google search results, your Facebook news feed, and select news sites before you even click on them.</p> */}
      </div>
      </div>

    );

  }

}

export default App;
