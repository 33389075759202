import React from 'react';
import logo from './img/logo.png';
import placeholder from './img/placeholder.png'


class Widget extends React.Component {

    render(){
      console.log(this.props.url)
      return(
        // <div className = "borderedArticle">
          <div className="borderedArticle">
              <div className="hide-on-small-only" style={{display:"flex", flexDirection:"row"}}>
                  {/* <img style={{borderRadius:"20px 0px 0px 20px"}} width="50%" src={placeholder}/> */}
                  <div>
                      <h5 className = "date" style={{paddingBottom:"10px"}}> {this.props.date} </h5>
                      <h5 style={{paddingBottom:"10px"}}> {this.props.source} </h5>
                      <h4 className = "headline" style={{paddingBottom:"20px"}}> {this.props.title.replace(/(\r\n|\n|\r)/gm, '"<br>"')} </h4>
                      <h5 className = "content" style={{paddingBottom:"10px"}}> {this.props.content} </h5>
                  </div>
                  </div>
          </div>
      );
    }

}

export default Widget;
