import React from 'react';
import stockDetails from './img/stock_info.png';
import authorDetails from './img/author_info.png';

import info from './img/info.png';
import placeholder from './img/placeholder.png'

import basiclogo from './img/basiclogo.png'
import {Pie, Doughnut} from 'react-chartjs-2';
import ModalImage from 'react-modal-image';


function returnReadInfo(time) {
  if (parseInt(time) <= 1) {
    return {label: "quick read", color: "#6FAE7E"}
  }
  else if (parseInt(time) <= 7) {
    return {label: "average read", color: "#E7BA53"}
  }
  else {
    return {label: "long read", color: "#99393A"}
  }
}


function getDescription(author, stock, slant) {
  if (slant.toLowerCase() == "buy") {
    return (author + " believes " + stock + " will rise in value")
  }
  if (slant.toLowerCase() == "sell") {
    return (author + " believes " + stock + " will fall in value")
  }
  if (slant.toLowerCase() == "hold") {
    return (author + " believes " + stock + " will hold its value")
  }
}

function getChartDescription(numBuy, numSell, numHold, stock) {
  numBuy = parseInt(numBuy)
  numSell = parseInt(numSell)
  numHold = parseInt(numHold)
  var total = numBuy+numSell+numHold
  if (numBuy/total > numSell/total) {
    return ({
      "0": parseInt(numBuy/total * 100) + "%",
      "1": " of articles predict ",
      "2": stock,
      "3": " will ",
      "4": " rise in value.",
    })
    // (parseInt(numBuy/total * 100) + "% of articles predict " + stock + " will " + "rise in value.")
  }
  else {
    return ({
      "0": parseInt(numSell/total * 100) + "%",
      "1": " of articles predict ",
      "2": stock,
      "3": " will ",
      "4": " fall in value.",
    })
  }
  // return (parseInt((Math.max(numBuy/total, numSell/total))*100) + "% of articles ")
}

function getChartData(props) {
  var chartData = {
    centerText: {
        display: true,
        text: "280"
    },
    labels: ['Hold', 'Buy', 'Sell', 'Unknown'],
    datasets: [
      {
        label: 'Context',
        borderColor: [
          '#9580D1',
          '#65B111',
          '#FF9F00',
          '#9E9E9E'
        ],
        backgroundColor: [
          '#EFECF8',
          '#E5F6D3',
          '#FFEED3',
          '#EEEEEE'
        ],
        hoverBackgroundColor: [
          '#9580D1',
          '#65B111',
          '#FF9F00',
          '#9E9E9E'
        ],
        data: [props.stock_n_hold, props.stock_n_buy, props.stock_n_sell]
      }
    ]
  }
  return chartData
}


class SourceWidget extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

    render(){
      const credibilityAddlInfo = {
        "true":{label: "Accurate", description: "In the three months following the publishing of this article, the stock price reflected the recommendation of this article.", color:"#65B111"},
        "false":{label: "Not accurate", description: "In the three months following the publishing of this article, the stock price did not reflect the recommendation of this article.", color:"#CD0000"},
        "null":{label: "N/A", description: "Not enough time has elapsed to assess the validity of this recommendation.", color:"#DADADA"},
        "undefined": {label: "Undefined", description: "Not enough time (<3 months) has elapsed to assess the validity of this recommendation.", color:"#DADADA"}
      };
      const slantAddlInfo = {
        "neutral": {color:"#9580D1", label: "neutral", backgroundColor:"#EFECF8",  description: "hold its value", performance: "held its value"},
        "buy":{color:"#65B111",label: "bullish", backgroundColor: "#E5F6D3", description: "rise in value", performance: "rose in value"},
        "null":{color:"#DADADA",label: "undefined", backgroundColor: "##F1F1F1 ", description: "n/a", performance: "n/a"},
        "sell":{color:"#FF9F00", label: "bearish", backgroundColor: "#FFEED3", description: "fall in value", performance : "fell in value"},
        "undefined": {label: "Undefined", description: "Undefined.", color:"#DADADA"}
      };
      const numStars = {
        "n/a": {0: "false", 1: "fa fa-star unchecked fa-lg", 2: "fa fa-star unchecked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1:"The author is unknown."},
        "undefined": {0: "false", 1: "fa fa-star unchecked fa-lg", 2: "fa fa-star unchecked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1:"'s credibility is not available."},
        "-1": {0: "false", 1: "fa fa-star unchecked fa-lg", 2: "fa fa-star unchecked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1:"'s credibility is not available."},
        "0": {0: "true", 1: "fa fa-star unchecked fa-lg", 2: "fa fa-star unchecked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1: " does not have enough information yet to assess credibility"},
        "1": {0: "true", 1: "fa fa-star checked fa-lg", 2: "fa fa-star unchecked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1: " has been ", description2: " correct across ", description3: " articles with an expected return of "},
        "2": {0: "true", 1: "fa fa-star checked fa-lg", 2: "fa fa-star checked fa-lg", 3: "fa fa-star unchecked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1: " has been ", description2: " correct across ", description3: " articles with an expected return of "},
        "3": {0: "true", 1: "fa fa-star checked fa-lg", 2: "fa fa-star checked fa-lg", 3: "fa fa-star checked fa-lg", 4: "fa fa-star unchecked fa-lg", 5: "fa fa-star unchecked fa-lg", description1: " has been ", description2: " correct across ", description3: " articles with an expected return of "},
        "4": {0: "true", 1: "fa fa-star checked fa-lg", 2: "fa fa-star checked fa-lg", 3: "fa fa-star checked fa-lg", 4: "fa fa-star checked fa-lg", 5: "fa fa-star unchecked fa-lg", description1: " has been ", description2: " correct across ", description3: " articles with an expected return of "},
        "5": {0: "true", 1: "fa fa-star checked fa-lg", 2: "fa fa-star checked fa-lg", 3: "fa fa-star checked fa-lg", 4: "fa fa-star checked fa-lg", 5: "fa fa-star checked fa-lg", description1: " has been ", description2: " correct across ", description3: " articles with an expected return of "},
        // "5": {1: "fa fa-star checked fa-lg", 2: "fa fa-star checked fa-lg", 3: "fa fa-star checked fa-lg", 4: "fa fa-star checked fa-lg", 5: "fa fa-star checked fa-lg", description: " has nearly perfect accuracy in past recommendations."},
      }

      return(
          <div className="borderedNobias">
              <div style={{ "paddingBottom":"20px", "paddingLeft":"20px", "paddingRight":"20px"}}>
                  {this.props.slant &&
                    <div>
                      <div className = "chart" style = {{"paddingTop": "5px"}}>
                        <Doughnut
                          data={getChartData(this.props)}
                          options={{
                            title:{
                              display:true,
                              fontSize:15,
                              // text:"MARKET BIAS",
                              text:"",
                              fontColor: "#777777",
                              fontWeight: "bold"
                            },
                            legend:{
                              display:false,
                              position:'right'
                            },
                            centerText: {
                              display: true,
                              text: "280"
                            }
                          }}
                        />
                      </div>
                      <div className = "grayText">Stock</div>
                      <div className = "stock"> {this.props.stock}</div>
                      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                      <div className = "grayText">Article Bias</div>
                      <div id = "market" className = "marketText" style={{ backgroundColor: slantAddlInfo[this.props.slant.toLowerCase()].backgroundColor, color:slantAddlInfo[this.props.slant.toLowerCase()].color}}> {slantAddlInfo[this.props.slant.toLowerCase()].label} </div>
                      <div className = "descriptionText"> This article predicts <b>{this.props.stock} </b> will <b> {slantAddlInfo[this.props.slant.toLowerCase()].description}</b> while <b>{getChartDescription(this.props.stock_n_buy, this.props.stock_n_sell, this.props.stock_n_hold, this.props.stock)[0]}</b> {getChartDescription(this.props.stock_n_buy, this.props.stock_n_sell, this.props.stock_n_hold, this.props.stock)[1]} <b>{getChartDescription(this.props.stock_n_buy, this.props.stock_n_sell, this.props.stock_n_hold, this.props.stock)[2]}</b> {getChartDescription(this.props.stock_n_buy, this.props.stock_n_sell, this.props.stock_n_hold, this.props.stock)[3]} <b>{getChartDescription(this.props.stock_n_buy, this.props.stock_n_sell, this.props.stock_n_hold, this.props.stock)[4]}</b>
                      <br></br><a href = {this.props.stockDetails} target="_blank" style={{fontSize:"14px"}}>More details</a>
                      {/* <ModalImage
                          small={info}
                          large={stockDetails}
                          alt="More details on XAR"
                        /> */}
                        </div>
                      {/* <div className = "grayText">Accuracy</div>
                      <div className = "mainText" style={{ color:credibilityAddlInfo[this.props.credibility.toLowerCase()].color}}> {credibilityAddlInfo[this.props.credibility].label}</div>
                      <div className = "descriptionText"> {credibilityAddlInfo[this.props.credibility].description}</div> */}
                      <div className = "grayText">Author Credibility</div>
                      <div className = {numStars[this.props.stars][1]}></div>
                      <div className = {numStars[this.props.stars][2]}></div>
                      <div className = {numStars[this.props.stars][3]}></div>
                      <div className = {numStars[this.props.stars][4]}></div>
                      <div className = {numStars[this.props.stars][5]}></div>
                      <div className = "descriptionText"> {this.props.author}{numStars[this.props.stars].description1}<b>{this.props.numCorrect}</b>{numStars[this.props.stars].description2}<b>{this.props.total}</b>{numStars[this.props.stars].description3}<b>{this.props.expReturn}</b>.
                      <br></br><a href = {this.props.authorDetails} target="_blank" style={{fontSize:"14px"}}>More details</a>
                      {/* <ModalImage
                          small={info}
                          large={authorDetails}
                          alt="More details on Koko Tumashi"
                        /> */}

                      </div>
                    </div>
                  }
                  <br></br>
                  <br></br>
                  <hr></hr>
                  <div className = "readmore">
                  To read more about how we determine slant and credibility, <a href="https://nobias.com/criteria" target="_blank">click here</a>
                  <img className = "basicLogo" src={basiclogo}></img>
                  </div>
              </div>
          </div>
      );

    }

}


export default SourceWidget;
